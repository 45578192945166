<template>
  <div>
    <TopNav />

    <div class="video-container">
      <video
        autoplay
        controls
        class="video"
        :poster="storageHost + '/videos/consultant.jpg'"
      >
        <source
          :src="storageHost + '/videos/consultant.mp4'"
          type="video/mp4"
        />
        <source
          :src="storageHost + '/videos/consultant.webm'"
          type="video/webm"
        />
      </video>
    </div>

    <v-container
      v-touch="{
        right: () => toHome(),
      }"
    >
      <div class="my-9">
        <div class="d-flex align-center justify-space-between my-6 my-sm-9">
          <div class="text-h3 font-weight-black" data-aos="fade-in">
            Consultant
          </div>
          <TextLogo :width="150" accent="#fcaf02" color="#21118D" />
        </div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          We are searching for some of the world's finest healthcare
          professionals to join and preview our unique and exclusive platform.
        </div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          The vast majority of private and fragmented healthcare systems across
          the world operate with a distinct lack of co-operation between the
          various independent medical providers. This currently presents itself
          to us as a huge setback for both the patient and the consultant.
        </div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          We recognise that healthcare professionals work in an already
          high-stress environment. So having to rely on unreliable, unprotected
          data, paired with a patient's insufficient medical history,
          unnecessarily elevates the difficulty of providing the best care
          possible.
        </div>

        <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
          PatRec will provide a uniform experience and service for patients and
          medical professionals across the world, protecting and transmitting
          patient information with military-grade encryption.
        </div>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="#fcaf02">mdi-stethoscope</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Apply medical knowledge to assist patients around the world.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="#fcaf02">mdi-radiology-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Easy access to patient files, streamlining the consultation
              process.</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="#fcaf02">mdi-message-text-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Communicate and consult securely.</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="#fcaf02">mdi-coffee</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Check the app at your leisure and take on any consultations you
              wish.</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item data-aos="fade-in">
          <v-list-item-icon>
            <v-icon color="#fcaf02">mdi-cash</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Simple access to financial incentives.</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <div class="mt-9">
          <v-btn
            large
            elevation="0"
            data-aos="zoom-in"
            rounded
            href="https://consultant.pat-rec.com/register"
            target="_blank"
            :block="$vuetify.breakpoint.xsOnly"
            class="mr-sm-2 mb-1 mb-sm-0"
            dark
            color="#fcaf02"
          >
            <v-icon class="mr-1">mdi-account-plus</v-icon>
            Register
          </v-btn>
          <v-btn
            large
            elevation="0"
            data-aos="zoom-in"
            rounded
            text
            href="https://consultant.pat-rec.com/login"
            target="_blank"
            :block="$vuetify.breakpoint.xsOnly"
            class="mr-sm-2 mb-1 mb-sm-0"
            color="#fcaf02"
          >
            <v-icon class="mr-1">mdi-login</v-icon>
            Login
          </v-btn>
          <v-btn
            large
            elevation="0"
            data-aos="zoom-in"
            rounded
            href="https://calendly.com/patrec-daniel/patrec-demo"
            target="_blank"
            :block="$vuetify.breakpoint.xsOnly"
            class="mr-sm-2 mb-1 mb-sm-0"
            text
            color="#fcaf02"
          >
            <v-icon class="mr-1">mdi-calendar-clock</v-icon>
            Book a demo
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import TextLogo from "@/components/shared/TextLogo";
import TopNav from "@/components/shared/TopNav";

export default {
  components: { TextLogo, TopNav },
  metaInfo: () => ({
    title: "Consultant - PatRec - Connecting Healthcare - Holden Knight Group",
  }),
  data() {
    return {
      storageHost: process.env.VUE_APP_STORAGE_HOST,
    };
  },
  methods: {
    toHome() {
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style scoped>
.text-h3 {
  color: #fcaf02;
}
</style>
